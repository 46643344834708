<template>
  <div class="article">
    <div class="tbl_wrap">
      <div class="tbl_option">
        <h5>수주 제품 현황</h5>
        <my-local-selectric
          :id="'SearchTypeSelect'"
          :options="search_type_options"
          :watch="search_type_id"
          @changeValue="
            $store.commit('setSearchTypeIdToPlan', $event);
            fetchList();
          "
        >
        </my-local-selectric>
        <div>
          <div class="input_text">
            <input
              type="date"
              placeholder="YYYY-MM-DD"
              :value="start_date"
              @change="changeStartDate($event)"
            />
          </div>
          <p>~</p>
          <div class="input_text">
            <input
              type="date"
              placeholder="YYYY-MM-DD"
              :value="end_date"
              @change="changeEndDate($event)"
            />
          </div>
        </div>
        <button type="button" class="btn_sub2" @click="fetchList()">
          검색
        </button>
      </div>
      <div class="mes_tbl_wrap">
        <table class="mes_tbl">
          <colgroup>
            <col v-for="(n, index) in 9" :key="index" />
          </colgroup>
          <thead>
            <tr>
              <th>매출처명</th>
              <th>제품명</th>
              <th>납품수량</th>
              <th>납품 요청일</th>
              <th>수주일</th>
              <th>생산계획일</th>
              <th>납품여부</th>
              <th>매출액</th>
              <th>생산가능여부 확인</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in sales_products" :key="item.id">
              <td class="text_left">
                {{
                  findInfoFromId(
                    companys,
                    findInfoFromId(sales, item.sales_id).company_id,
                  ).name
                }}
              </td>
              <td class="text_left">
                {{ findInfoFromId(products, item.product_id).name }}
              </td>
              <td class="text_right">
                <b>{{ $makeComma(item.quantity) }}</b>
                {{
                  findInfoFromId(
                    units,
                    findInfoFromId(products, item.product_id).stock_unit_id,
                  ).name
                }}
              </td>
              <td>
                {{ findInfoFromId(sales, item.sales_id).delivery_date }}
              </td>
              <td>
                {{ findInfoFromId(sales, item.sales_id).input_date }}
              </td>
              <td>
                {{
                  item.plan_start_date != null
                    ? `${item.plan_start_date}~${item.plan_end_date}`
                    : ''
                }}
              </td>
              <td>
                {{ item.output_yn ? 'Y' : 'N' }}
              </td>
              <td class="text_right">
                &#8361; {{ $makeComma(item.total_cost) }}
              </td>
              <td>
                <button class="btn_tbl" @click="pushMaterialToList(item)">
                  확인
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { yyyymmdd } from '@/utils/func';
import FETCH_MIXIN from '@/mixins/fetch';
import MODAL_MIXIN from '@/mixins/modal';
import SPINNER_MIXIN from '@/mixins/spinner';
import MyLocalSelectric from '@/layouts/components/MyLocalSelectric';

export default {
  mixins: [FETCH_MIXIN, MODAL_MIXIN, SPINNER_MIXIN],
  components: {
    MyLocalSelectric,
  },
  data() {
    return {
      search_type_options: [
        { label: '수주 등록일 기준', value: 0 },
        { label: '납품 요청일 기준', value: 1 },
      ],
      showCalendarModal: false,
    };
  },
  computed: {
    ...mapGetters({
      search_type_id: 'getSearchTypeIdFromPlan',
      start_date: 'getStartDateFromPlan',
      end_date: 'getEndDateFromPlan',
      sales: 'getSalesFromPlan',
      managementData: 'getManagementDataFromPlan',
      units: 'getUnitCodes',
      products: 'getProduct',
      companys: 'getCompany',
      boxs: 'getBoxsNotSorted',
      boms: 'getBom',
      materials: 'getMaterial',
      material_stock: 'getMaterialTotalStock',
      material_occupied_stock: 'getMaterialOccupiedStock',
      product_groups: 'getProductGroup',
      material_groups: 'getMaterialGroup',
      unit_conversions: 'getUnitConversion',
    }),
    sales_products() {
      if (this.sales.length < 1 || this.boxs.length < 1) {
        return [];
      } else {
        let sales = this.lodash.clonedeep(this.sales);
        if (this.search_type_id == 0) {
          sales.sort((a, b) => new Date(a.input_date) - new Date(b.input_date));
        } else {
          sales.sort(
            (a, b) => new Date(a.delivery_date) - new Date(b.delivery_date),
          );
        }
        let sales_products = sales.map(x => x.product_list);

        sales_products = sales_products.reduce((a, b) => a.concat(b));
        sales_products = sales_products.map(x => {
          if (x.box_unit_id != null) {
            const boxUnit = this.findInfoFromId(this.boxs, x.box_unit_id);

            if (boxUnit != undefined) {
              x.total_quantity = this.$decimalMul(
                this.$makeNumber(x.quantity),
                boxUnit.quantity,
              );
            } else {
              x.total_quantity = x.quantity;
            }
          } else {
            x.total_quantity = x.quantity;
          }
          return x;
        });
        return sales_products;
      }
    },
  },
  methods: {
    pushMaterialToList(item) {
      const selectedRow = this.lodash.clonedeep(item);

      let bom = this.boms.find(x => x.product_id == selectedRow.product_id);
      console.log(bom);
      // return;
      if (bom == undefined) {
        const filteredGroup = this.product_groups.filter(
          x => x.member_product_id == selectedRow.product_id,
        );
        if (filteredGroup.length > 0) {
          //여기서 부모 제품 중 하나를 선택 일단은 첫번째꺼로 진행되도록 짜겠음.
          console.log(filteredGroup[0].group_product_id, '@@@@@@@@');
          selectedRow.product_id = filteredGroup[0].group_product_id;
          selectedRow.total_quantity = this.$decimalMul(
            selectedRow.total_quantity,
            filteredGroup[0].quantity,
          );
          bom = this.boms.find(
            x => x.product_id == filteredGroup[0].group_product_id,
          );
          if (bom == undefined) {
            console.log('BOM 미등록');
            this.openOneButtonModal('생산불가', 'BOM미등록');
          }
        } else {
          console.log('BOM 미등록');
          this.openOneButtonModal('생산불가', 'BOM미등록');
        }
      }

      let temp = this.lodash.clonedeep(bom.bom_process).map(process => {
        const processTemp = process.bom_resource.map(el => {
          const newElement = this.lodash.clonedeep(el);

          if (newElement.product_id == null) {
            newElement.bom_parents_id = selectedRow.bom_parents_id;
            newElement.product_unique_id = selectedRow.id;
            if (!newElement.percent_yn) {
              newElement.need_quantity = this.lodash.clonedeep(
                Number(
                  this.$decimalMul(
                    newElement.quantity,
                    selectedRow.total_quantity,
                  ),
                ),
              );
            } else {
              const materialInfo = this.findInfoFromId(
                this.materials,
                newElement.material_id,
              );
              const productInfo = this.findInfoFromId(
                this.products,
                selectedRow.product_id,
              );

              const cvr_ratio = this.unit_conversions.find(
                x =>
                  x.in_unit_id == materialInfo.using_unit_id &&
                  x.out_unit_id == productInfo.stock_unit_id,
              );

              if (cvr_ratio != undefined) {
                console.log(
                  'selectedRow.total_quantity',
                  selectedRow.total_quantity,
                );
                console.log('newElement.quantity', newElement.quantity);
                newElement.need_quantity = this.$decimalMul(
                  this.$decimalMul(
                    selectedRow.total_quantity,
                    this.$decimalDiv(newElement.quantity, 100),
                  ),
                  cvr_ratio.cvr_ratio,
                );
              } else {
                console.log(
                  'selectedRow.total_quantity',
                  selectedRow.total_quantity,
                );
                console.log('newElement.quantity', newElement.quantity);
                newElement.need_quantity = this.$decimalMul(
                  selectedRow.total_quantity,
                  this.$decimalDiv(newElement.quantity, 100),
                );
              }
            }

            const materialInfo = this.findInfoFromId(
              this.materials,
              newElement.material_id,
            );
            const cvr_ratio = this.unit_conversions.find(
              x =>
                x.in_unit_id == materialInfo.incoming_unit_id &&
                x.out_unit_id == materialInfo.using_unit_id,
            );
            newElement.cvr_ratio =
              cvr_ratio != undefined ? cvr_ratio.cvr_ratio : 1;
            const qTemp = this.$decimalSub(
              this.$makeNumber(newElement.need_quantity),
              this.$makeNumber(this.getMaterialStock(newElement.material_id)),
            );
            if (
              this.$makeNumber(newElement.need_quantity) >
              this.$makeNumber(this.getMaterialStock(newElement.material_id))
            ) {
              newElement.more = qTemp;
              return newElement;
            } else return true;
          }
        });
        return processTemp;
      });

      temp = temp.reduce((a, b) => a.concat(b));
      console.log(temp);
      if (temp.find(x => x != true) == undefined) {
        this.openOneButtonModal('생산가능', '생산이 가능합니다.');
      } else {
        temp = temp.filter(x => x != true);
        let str = '';
        let 중복제거 = [];
        temp.forEach(element => {
          if (!중복제거.includes(element.material_id)) {
            str +=
              element.name +
              '-' +
              this.findInfoFromId(this.materials, element.material_id)
                .standard +
              ' : ' +
              this.$makeComma(element.more) +
              this.findInfoFromId(
                this.units,
                this.findInfoFromId(this.materials, element.material_id)
                  .incoming_unit_id,
              ).name +
              ' 부족 <br/>';
            중복제거.push(element.material_id);
          }
        });
        str += '======================<br/>재료가 부족합니다.';
        this.openOneButtonModal('생산불가', str);
      }
    },
    getMaterialStock(id) {
      if (this.material_stock != undefined) {
        let hit = this.material_stock.find(x => x.material_id == id);
        const currentStock = hit != undefined ? Number(hit.stock_quantity) : 0;
        if (this.material_occupied_stock != undefined) {
          const hit = this.material_occupied_stock.find(
            x => x.material_id == id,
          );
          if (hit != undefined) {
            return currentStock - hit.quantity;
          } else {
            return currentStock;
          }
        } else {
          return currentStock;
        }
      } else {
        return 0;
      }
    },
    getBomFromProductId(id) {
      if (id == undefined || id == null) {
        return null;
      }
      const hit = this.boms.find(x => x.product_id == id);
      return hit != undefined ? hit : null;
    },
    fetchList() {
      this.selectRow(-1);
      if (this.search_type_id == 1) {
        this.FETCH_WITH_PAYLOAD(
          'FETCH_SALES_TO_PLAN',
          {
            start_date: this.start_date,
            end_date: this.end_date,
          },
          '수주',
        );
      } else {
        this.FETCH_WITH_PAYLOAD(
          'FETCH_SALES_TO_PLAN_SEARCH_BY_INPUT_DATE',
          {
            start_date: this.start_date,
            end_date: this.end_date,
          },
          '수주',
        );
      }
    },
    async submitForm() {
      this.showSpinner();
      const payload = this.lodash.clonedeep(this.managementData);
      await this.$store
        .dispatch('UPDATE_SALES_PRODUCT_PLAN', payload)
        .then(() => {
          this.openOneButtonModal('저장 성공', '생산 계획을 저장하였습니다');
          this.fetchList();
        })
        .catch(() => {
          this.openOneButtonModal(
            '저장 실패',
            '생산 계획 저장 중 오류가 발생하였습니다',
          );
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
    changePlanStartDate(e) {
      if (e.target.value != '') {
        if (e.target.value > this.managementData.plan_end_date) {
          this.openOneButtonModal(
            '계획기간 오류',
            `계획기간 최후날짜(${this.managementData.plan_end_date}) 이후의<br>날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.managementData.plan_start_date;
        } else {
          this.managementData.plan_start_date = e.target.value;
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
      }
    },
    changePlanEndDate(e) {
      if (e.target.value != '') {
        if (e.target.value < this.managementData.plan_start_date) {
          this.openOneButtonModal(
            '계획기간 오류',
            `계획기간 최초날짜(${this.managementData.plan_start_date}) 이전의<br>날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.managementData.plan_end_date;
        } else {
          this.managementData.plan_end_date = e.target.value;
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
      }
    },

    selectRow(index) {
      this.$store.commit('setSelectedIndexToPlan', index);
      if (index == -1) {
        this.$store.commit('setManagementDataToPlan', null);
      } else {
        const payload = this.lodash.clonedeep(this.sales_products[index]);
        const sales = this.lodash.clonedeep(
          this.findInfoFromId(this.sales, payload.sales_id),
        );
        payload.input_date = sales.input_date;
        payload.delivery_date = sales.delivery_date;
        payload.company_id = sales.company_id;
        if (
          payload.plan_start_date == undefined ||
          payload.plan_start_date == null
        ) {
          payload.plan_start_date = this.lodash.clonedeep(sales.input_date);
          payload.plan_end_date = this.lodash.clonedeep(sales.delivery_date);
        }

        this.$store.commit('setManagementDataToPlan', payload);
      }
    },
    changeStartDate(e) {
      if (e.target.value != '') {
        if (e.target.value > this.end_date) {
          this.openOneButtonModal(
            '검색기간 오류',
            `검색기간 최후날짜(${this.end_date}) 이후의<br>날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.start_date;
        } else {
          this.$store.commit('setStartDateToPlan', e.target.value);
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit('setStartDateToPlan', yyyymmdd(new Date()));
      }
    },
    changeEndDate(e) {
      if (e.target.value != '') {
        if (e.target.value < this.start_date) {
          this.openOneButtonModal(
            '검색기간 오류',
            `검색기간 최초날짜(${this.start_date}) 이전의<br>날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.end_date;
        } else {
          this.$store.commit('setEndDateToPlan', e.target.value);
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit('setEndDateToPlan', yyyymmdd(new Date()));
      }
    },
  },
  async created() {
    if (this.start_date == null) {
      var date = new Date();
      this.$store.commit('setEndDateToPlan', yyyymmdd(date));
      var date2 = new Date(date.setDate(date.getDate() - 14));
      this.$store.commit('setStartDateToPlan', yyyymmdd(date2));
    }

    if (this.units.length < 1) {
      await this.FETCH('FETCH_UNIT', '단위');
    }
    if (this.products.length < 1) {
      await this.FETCH('FETCH_PRODUCT_WITH_COMPANY', '제품');
    }
    if (this.companys.length < 1) {
      await this.FETCH('FETCH_COMPANY', '매출처');
    }

    if (this.boxs.length < 1) {
      await this.FETCH('FETCH_BOX', '박스');
    }

    if (this.boms.length < 1) {
      await this.FETCH('FETCH_BOM', 'BOM');
    }
    if (this.materials.length < 1) {
      await this.FETCH('FETCH_MATERIAL_WITH_COMPANY', '자재');
    }
    if (this.unit_conversions.length < 1) {
      await this.FETCH('FETCH_UNIT_CONVERSION', '단위환산');
    }
    if (this.product_groups.length < 1) {
      await this.FETCH('FETCH_PRODUCT_GROUP', '생산 제품');
    }
    if (this.material_groups.length < 1) {
      await this.FETCH('FETCH_MATERIAL_GROUP', '그룹 자재');
    }

    await this.FETCH('FETCH_MATERIAL_TOTAL_STOCK', '자재 총재고');
    await this.FETCH('FETCH_MATERIAL_OCCUPIED_STOCK', '자재 사용중 재고');
    this.FETCH_WITH_PAYLOAD(
      'FETCH_SALES_TO_PLAN_SEARCH_BY_INPUT_DATE',
      {
        start_date: this.start_date,
        end_date: this.end_date,
      },
      '수주',
    );
  },
};
</script>

<style lang="scss" scoped></style>
